var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加供货商品",
        visible: _setup.props.show,
        width: "1000px",
        "before-close": _setup.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_setup.props, "show", $event)
        },
        open: _setup.open,
      },
    },
    [
      _c(
        "div",
        [
          [
            _c(
              "div",
              { staticClass: "outer supply-chain" },
              [
                _c(
                  "search-panel",
                  {
                    attrs: { "show-btn": "", flex: "" },
                    on: {
                      getList: function ($event) {
                        return _setup.getList(1)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("商品类目")]),
                        _c(_setup.ChangeCat, {
                          attrs: { "parent-id": 1 },
                          on: { change: _setup.handleChange },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("商品ID")]),
                        _c("el-input-number", {
                          attrs: {
                            size: "mini",
                            controls: false,
                            min: 0,
                            precision: 0,
                            placeholder: "请输入内容",
                            clearable: "",
                          },
                          model: {
                            value: _setup.params.relationGoodsId,
                            callback: function ($$v) {
                              _vm.$set(_setup.params, "relationGoodsId", $$v)
                            },
                            expression: "params.relationGoodsId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("商品名称")]),
                        _c("el-input", {
                          attrs: {
                            clearable: "",
                            placeholder: "请输入内容",
                            size: "mini",
                          },
                          on: {
                            clear: function ($event) {
                              return _setup.getList(1)
                            },
                          },
                          model: {
                            value: _setup.params.relationGoodsName,
                            callback: function ($$v) {
                              _vm.$set(_setup.params, "relationGoodsName", $$v)
                            },
                            expression: "params.relationGoodsName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { slot: "button-right", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _setup.operation()
                          },
                        },
                        slot: "button-right",
                      },
                      [_vm._v(" 重 置 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _setup.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _setup.tableData,
                      "element-loading-text": "拼命加载中",
                      "row-key": "relationGoodsId",
                      "header-cell-style": {
                        background: "#f5f7fa",
                      },
                      size: "medium",
                      "expand-row-keys": _setup.expandRowKeys,
                    },
                    on: { "selection-change": _setup.selectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        "reserve-selection": true,
                        selectable: _setup.checkboxTrue,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { "margin-left": "103px" },
                                  attrs: {
                                    data: row.tradeGoodsSkuDtoList,
                                    size: "medium",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "商品规格", width: "300" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (prop) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "wd-flex wd-flex-align-center",
                                                },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      width: "50px",
                                                      height: "50px",
                                                    },
                                                    attrs: {
                                                      src: prop.row.imageIds,
                                                      "preview-src-list": [
                                                        prop.row.imageIds,
                                                      ],
                                                      fit: "cover",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "margin-l5",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            prop.row.specValue
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "店铺销售价",
                                      width: "180",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(+scope.row.sellPrice) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "supplyPrice",
                                      label: "供货价",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (prop) {
                                            return [
                                              +row.isAdded !== 1
                                                ? _c("el-input-number", {
                                                    class: {
                                                      input_box:
                                                        !prop.row.editPrice,
                                                    },
                                                    attrs: {
                                                      size: "mini",
                                                      controls: false,
                                                      min: 0,
                                                      precision: 2,
                                                      placeholder:
                                                        "请设置供货价",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: prop.row.editPrice,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          prop.row,
                                                          "editPrice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "prop.row.editPrice",
                                                    },
                                                  })
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        +prop.row.supplyPrice
                                                      )
                                                    ),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "isAdded", label: "状态", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    +row.isAdded === 1 ? "已添加" : "未添加"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "relationGoodsId",
                        label: "商品ID",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "relationGoodsName", label: "商品名称" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "backendCategoryPath", label: "商品类目" },
                    }),
                  ],
                  1
                ),
                _setup.tableData && _setup.tableData.length > 0
                  ? _c("el-pagination", {
                      attrs: {
                        "current-page": _setup.params.currentPage,
                        "page-sizes": [10, 20, 30, 50],
                        "page-size": _setup.params.nowPageSize,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _setup.params.count,
                      },
                      on: {
                        "size-change": _setup.handleSizeChange,
                        "current-change": _setup.handleCurrentChange,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _setup.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _setup.submit },
            },
            [_vm._v("添加选中项")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script setup>
import { ref, reactive, getCurrentInstance } from 'vue';
import ChangeCat from '@/components/common/changeCat.vue';

const app = getCurrentInstance().proxy;

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits('clear', 'success');

const search = {
  backendCategoryId: undefined,
  start: undefined,
  relationGoodsId: undefined,
  relationGoodsName: undefined,
  currentPage: 1,
  nowPageSize: 10,
  count: 0,
};
const params = reactive({ ...search });
const loading = ref(false);
const tableData = ref([]);
const updateList = ref([]);
const multipleTable = ref(null);
const expandRowKeys = ref([]);

function handleClose() {
  emit('clear');
}
function submit() {
  const list = multipleTable.value.selection;
  if (!list.length) {
    app.$message.error('请勾选商品');
    return;
  }
  if (list.length > 100) {
    app.$message.error('最多选择100个商品');
    return;
  }
  let loopSwitch = true;
  goodsLoop: for (const goods of list) {
    for (const sku of goods.tradeGoodsSkuDtoList) {
      if (!sku.editPrice || sku.editPrice === 0) {
        app.$message.error(`商品（${goods.relationGoodsName}）未设置供货价`);
        loopSwitch = false;
        break goodsLoop;
      }
      if (sku.editPrice > sku.sellPrice) {
        app.$message.error(`商品（${goods.relationGoodsName}）供货价不能大于销售价`);
        loopSwitch = false;
        break goodsLoop;
      }
      sku.supplyPrice = sku.editPrice;
    }
  }
  if (loopSwitch) {
    const params = list;
    app.$confirm('是否确认提交?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(async () => {
      const res = await app.$axios.post(app.$api.supplychain.add, params);
      if (res.code !== 0) return;
      app.$message.success('操作成功！');
      emit('success');
      handleClose();
    }).catch(() => {});
  }
  console.log(list);
}

function handleChange(value) {
  if (value && value.length > 0) {
    params.backendCategoryId = value[value.length - 1];
  } else {
    params.backendCategoryId = '';
  }
}
// 分页控制
function handleSizeChange(val) {
  params.nowPageSize = val;
  params.currentPage = 1;
  getList();
}
function handleCurrentChange(val) {
  params.currentPage = val;
  getList();
}
async function getList(num) {
  if (num) params.currentPage = num;
  loading.value = true;
  const res = await app.$axios.post(app.$api.supplychain.pageForAdd, {
    ...params,
    currentPage: params.currentPage,
    pageSize: params.nowPageSize,
    nowPageSize: undefined,
    count: undefined,
  });
  loading.value = false;
  if (res.code === 0) {
    tableData.value = res.data.records;
    params.count = Number(res.data.total);
  }
}
function operation() {
  for (const key in search) {
    params[key] = search[key];
  }
  getList(1);
}

// 禁用
function checkboxTrue(row) {
  return +row.isAdded === 0; // 不禁用
}
function selectionChange(selection) {
  expandRowKeys.value = selection.map((item) => item.relationGoodsId);
}
function open() {
  updateList.value = [];
  multipleTable.value?.clearSelection();
  getList(1);
}
</script>

<template>
  <el-dialog
    title="添加供货商品"
    :visible.sync="props.show"
    width="1000px"
    :before-close="handleClose"
    @open="open"
  >
    <div>
      <template>
        <div class="outer supply-chain">
          <search-panel
            show-btn
            flex
            @getList="getList(1)"
          >
            <div>
              <p>商品类目</p>
              <ChangeCat
                :parent-id="1"
                @change="handleChange"
              />
            </div>
            <!-- <div>
              <p>供货状态</p>
              <el-select
                v-model="params.start"
                placeholder="请选择状态"
                size="mini"
                clearable
                @change="getList(1)"
              >
                <el-option
                  label="供货中"
                  :value="1"
                />
                <el-option
                  label="未供货"
                  :value="0"
                />
              </el-select>
            </div> -->
            <div>
              <p>商品ID</p>
              <el-input-number
                v-model="params.relationGoodsId"
                size="mini"
                :controls="false"
                :min="0"
                :precision="0"
                placeholder="请输入内容"
                clearable
              />
            </div>
            <div>
              <p>商品名称</p>
              <el-input
                v-model="params.relationGoodsName"
                clearable
                placeholder="请输入内容"
                size="mini"
                @clear="getList(1)"
              />
            </div>

            <el-button
              slot="button-right"
              size="mini"
              @click="operation()"
            >
              重 置
            </el-button>
          </search-panel>
          <el-table
            ref="multipleTable"
            v-loading="loading"
            :data="tableData"
            element-loading-text="拼命加载中"
            style="width: 100%"
            row-key="relationGoodsId"
            :header-cell-style="{
              background: '#f5f7fa',
            }"
            size="medium"
            :expand-row-keys="expandRowKeys"
            @selection-change="selectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
              :selectable="checkboxTrue"
            />
            <el-table-column type="expand">
              <template slot-scope="{row}">
                <el-table
                  :data="row.tradeGoodsSkuDtoList"
                  style="margin-left: 103px"
                  size="medium"
                >
                  <el-table-column
                    label="商品规格"
                    width="300"
                  >
                    <template slot-scope="prop">
                      <div class="wd-flex wd-flex-align-center">
                        <el-image
                          style="width: 50px; height: 50px"
                          :src="prop.row.imageIds"
                          :preview-src-list="[prop.row.imageIds]"
                          fit="cover"
                        />
                        <div class="margin-l5">
                          {{ prop.row.specValue }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="店铺销售价"
                    width="180"
                  >
                    <template slot-scope="scope">
                      {{ +scope.row.sellPrice }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="supplyPrice"
                    label="供货价"
                  >
                    <template slot-scope="prop">
                      <el-input-number
                        v-if="+row.isAdded !== 1"
                        v-model="prop.row.editPrice"
                        :class="{
                          'input_box': !prop.row.editPrice
                        }"
                        size="mini"
                        :controls="false"
                        :min="0"
                        :precision="2"
                        placeholder="请设置供货价"
                        clearable
                      />
                      <span v-else>{{ +prop.row.supplyPrice }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column
              prop="isAdded"
              label="状态"
              width="100"
            >
              <template slot-scope="{row}">
                {{ +row.isAdded === 1 ? '已添加' : '未添加' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="relationGoodsId"
              label="商品ID"
              width="100"
            />
            <el-table-column
              prop="relationGoodsName"
              label="商品名称"
            />
            <el-table-column
              prop="backendCategoryPath"
              label="商品类目"
            />
          </el-table>
          <el-pagination
            v-if="tableData && tableData.length > 0"
            :current-page="params.currentPage"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="params.nowPageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="params.count"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </template>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="submit"
      >添加选中项</el-button>
    </span>
  </el-dialog>
</template>

<style scoped lang="scss">
.input_box :deep(input) {
  border-color: red;
}
</style>
